<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm">
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <div class="row q-py-sm horizontal-line" v-if="form._id !== ''">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>
                  {{ $t('label.display_id') }}
                </span>
              </div>
              <TagInputContent :tagValue="form.display_id" v-if="form._id !== ''" class="col q-mr-md"></TagInputContent>
            </div>
            <div class="row q-py-sm horizontal-line">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>{{ $t('label.goal.goal_name') }}</span>
              </div>
              <q-input
                outlined
                dense
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.goalName]"
                v-model="form.title"
                :placeholder="$t('label.goal.goal_name')"
                class="col q-mr-md"
                ref="refTitle"
              />
            </div>
            <div class="q-mx-md q-mt-sm">シナリオの最後のメッセージ作成してください。</div>
            <div class="horizontal-line">
              <MessageSelector v-model="form.details" ref="messageSelector" :answerNumber="answerNumber" />
            </div>
            <div class="q-px-md" :class="!form.is_continue_question ? 'horizontal-line' : ''">
              <label class="text-grey-9 text-bold">{{ $t('label.common.messages.is_continue_question') }}</label>
              <q-toggle round v-model="form.is_continue_question" />
            </div>
            <div v-if="form.is_continue_question" class="row q-py-sm horizontal-line">
              <div class="q-ml-md text-grey-9 col-1 flex items-start">
                <span>{{ $t('label.common.messages.question') }}</span>
              </div>
              <InputEmojiSelector
                v-model="form.message"
                type="textarea"
                :placeholder="$t('label.common.messages.question')"
                :rules="[(val) => (val && val.length > 0) || requiredRule.goalMessage]"
                class="col q-mr-md"
              />
            </div>
            <div v-if="form.is_continue_question">
              <AnswerForm v-model="form.answers" :isDeleteFirst="true" ref="answerForm" />
            </div>
          </div>
          <div class="row no-wrap items-center q-mt-md q-pr-md">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IGoal, IInitialCampaign, IPushCampaign } from '@/utils/types'
import { ACTION_GOAL } from '@/store/actions'
import { constant, FREETEXT_OPTION_TYPE } from '@/utils/constants'
// import MessageSelector from '@/components/common/selectors/MessageSelector.vue'
// import AnswerForm from '@/components/common/answer/AnswerForm.vue'
import MessageSelector from '@/components/story/common/selectors/MessageSelector.vue'
import AnswerForm from '@/components/story/common/answer/AnswerForm.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { MessageSelector, AnswerForm, InputEmojiSelector, TagInputContent },
  directives: { maska },
  emits: ['update:onSelect', 'update:onCreate'],
})
export default class GoalFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IGoal

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop({ default: '' })
  appId!: string

  form: IGoal = {
    _id: '',
    app_id: this.appId,
    title: '',
    details: [],
    is_continue_question: false,
    message: '',
    answers: {
      type: constant.ANSWER_TYPE.TEXT,
      text_answers: [],
      image_answers: [],
      imagemap_answer: {},
      button_answer: {},
      freetext_answer: { option_type: FREETEXT_OPTION_TYPE.FREETEX, error_message: '' },
    },
    applied_campain: 0,
  }

  get requiredRule() {
    const requiredRule = {
      goalName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.goal.goal_name'),
      }),
      goalMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get answerNumber() {
    let count: number | undefined = 0

    if (this.form.answers?.type === constant.ANSWER_TYPE.TEXT) {
      count = this.form.answers.text_answers?.length
    } else if (this.form.answers?.type === constant.ANSWER_TYPE.IMAGE) {
      count = this.form.answers.image_answers?.length
    } else if (this.form.answers?.type === constant.ANSWER_TYPE.BUTTON) {
      count = this.form.answers.button_answer?.actions?.length
    } else {
      count = this.form.answers?.imagemap_answer?.tappable_area?.length
    }

    return count
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.goal.add_new_goal') : this.$t('label.goal.edit_goal')
  }

  get goals(): IGoal[] {
    return this.$store.getters.goals || []
  }

  get isExistTextAnswers() {
    if (
      this.form.answers &&
      this.form.answers.type === constant.ANSWER_TYPE.TEXT &&
      this.form.answers.text_answers &&
      this.form.answers.text_answers.length > 0
    ) {
      return true
    }
    return false
  }

  onSubmit() {
    const isValid = this.$refs.messageSelector.validate()
    let isValidAnswer = true
    if (this.form.is_continue_question) {
      isValidAnswer = this.$refs.answerForm.validate()
    } else {
      this.form.message = ''
      this.form.answers = {
        type: constant.ANSWER_TYPE.TEXT,
        text_answers: [],
        image_answers: [],
        imagemap_answer: {},
        button_answer: {},
        freetext_answer: { option_type: FREETEXT_OPTION_TYPE.FREETEX, error_message: '' },
      }
    }

    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !isValid || !isValidAnswer) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let goal = null
    const isNew = this.isNew
    if (isNew) {
      goal = await dispatch(ACTION_GOAL.ADD, {
        ...this.form,
      })
    } else {
      goal = await dispatch(ACTION_GOAL.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (goal) {
      if (isNew) {
        this.$emit('update:onCreate', goal)
      } else {
        this.$emit('update:onSelect', goal)
      }
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()

      if (this.$refs.messageSelector) {
        this.$refs.messageSelector.validate()
      }

      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    if (!this.form.answers) {
      this.form.answers = {}
    }
    if (!this.form.answers.button_answer) {
      this.form.answers.button_answer = { is_carousel: false }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>
