
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ObjectUtils } from '@/utils/objects'
import { IGoal, IInitialCampaign, IMessage, IPushCampaign, IValidRequest } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BaseFormDialogMixin extends Vue {
  @Prop()
  modalVisible!: boolean

  parentForm = {
    _id: '',
    app_id: '',
    display_id: '',
  }

  initForm = { _id: '', app_id: '' }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get isNew() {
    return !this.parentForm?._id
  }

  checkDraftValue() {
    if (Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)) {
      this.notifyUnSaveForm()
    } else {
      this.visible = false
    }
  }

  notifyUnSaveForm() {
    this.$q
      .dialog({
        title: this.$t('messages.confirm'),
        message: this.$t('messages.draft_form_unsaved'),
        cancel: {
          flat: true,
          label: this.$t('messages.no'),
        },
        ok: {
          flat: true,
          label: this.$t('messages.yes'),
        },
        persistent: true,
      })
      .onOk(async () => {
        this.visible = false
      })
  }

  onCancel() {
    this.checkDraftValue()
  }
}
