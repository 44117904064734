
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IQuestion } from '@/utils/types'
import QuestionFormModal from '@/components/question/QuestionFormModal.vue'

@Options({
  components: { QuestionFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect', 'update:onCreate'],
})
export default class QuestionSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalQuestionlVisible = false
  selectedQuestion: IQuestion = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get questions(): IQuestion[] {
    const questions = this.$store.getters.questions
    return [...questions].reverse()
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(question: IQuestion) {
    return question
  }

  @Emit('update:onCreate')
  onCreate(question: IQuestion) {
    return question
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedQuestion = {
      app_id: this.appId,
    }

    this.modalQuestionlVisible = true
  }

  onCloseModal() {
    this.modalQuestionlVisible = false
    this.selectedQuestion = {}
  }
}
