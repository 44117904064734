<template>
  <div class="images q-mb-sm list-x">
    <img
      v-for="(image, i) in images"
      :src="image.image_url"
      style="height: 150px; max-width: 150px"
      :class="!uniqueId ? '' : 'cursor-pointer'"
      @click="handleAnswerClick(image._id, image.title === '' ? image.label : image.title)"
      :key="i"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IChat } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class AnswerImageColumn extends Vue {
  @Prop()
  msg!: IChat

  get uniqueId() {
    return this.msg.uniqueId
  }

  get images() {
    return this.msg.image_answers
  }

  handleAnswerClick(answerId: string, title: string) {
    if (!this.uniqueId) {
      return
    }
    this.$emit('update:handleAnswerClick', this.msg.uniqueId, answerId, title)
  }

  mounted() {
    const slider = document.querySelector('.list-x') as HTMLElement
    let isDown = false
    let startX
    let scrollLeft
    if (slider) {
      slider.addEventListener('mousedown', (e) => {
        isDown = true
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3
        slider.scrollLeft = scrollLeft - walk
        console.log(walk)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

// .images {
//   max-width: 280px;
//   overflow-x: scroll;
//   white-space: nowrap;
// }
.images {
  max-width: 280px;
  // overflow-x: auto;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  will-change: transform;
  transition: all 0.2s;
}
</style>
