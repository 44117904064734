
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IMessage } from '@/utils/types'
import MessagesFormModal from '@/components/messages/MessagesFormModal.vue'

@Options({
  components: { MessagesFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect', 'update:onCreate'],
})
export default class MessageSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalMessageVisible = false
  selectedMessage: IMessage = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get messages(): IMessage[] {
    const messages = this.$store.getters.messages
    return [...messages].reverse()
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(message: IMessage) {
    return message
  }

  @Emit('update:onCreate')
  onCreate(message: IMessage) {
    return message
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.modalMessageVisible = true
    this.selectedMessage = {
      app_id: this.appId,
    }
  }

  onCloseModal() {
    this.modalMessageVisible = false
    this.selectedQuestion = {}
  }
}
