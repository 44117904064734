<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm"> {{ modalTitle }} </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <div class="max-height-dialog">
          <q-form ref="formRef" greedy>
            <div class="row q-py-sm horizontal-line" v-if="form._id !== ''">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>
                  {{ $t('label.display_id') }}
                </span>
              </div>
              <TagInputContent :tagValue="form.display_id" v-if="form._id !== ''" class="col q-mr-md"></TagInputContent>
            </div>
            <div class="row q-py-sm horizontal-line">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>{{ $t('label.message.message_name') }}</span>
              </div>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.messageName]"
                outlined
                v-model="form.title"
                dense
                :placeholder="$t('label.message.message_name')"
                class="col q-mr-md"
                ref="refTitle"
              />
            </div>
            <div class="q-mx-md q-mt-sm">テキスト／画像／画像マップを使用して、メッセージを作成してください。</div>
            <div>
              <MessageSelector v-model="form.messages" ref="messageSelector" />
            </div>
          </q-form>
        </div>
        <div class="row no-wrap items-center q-mt-md q-pr-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }} </q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_MESSAGES } from '@/store/actions'
import { IInitialCampaign, IMessage, IPushCampaign } from '@/utils/types'
// import MessageSelector from '@/components/common/selectors/MessageSelector.vue'
import MessageSelector from '@/components/story/common/selectors/MessageSelector.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { MessageSelector, TagInputContent },
  directives: { maska },
  emits: ['update:onSelect', 'update:onCreate'],
})
export default class MessagesFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IMessage

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop({ default: '' })
  appId!: string

  files: File[] = []

  form: IMessage = {
    _id: '',
    app_id: this.appId,
    messages: [
      { type: 'message', images: [], message: '', imagemap_answer: {}, button_answer: { is_carousel: false } },
    ],
    applied_campain: 0,
  }

  get requiredRule() {
    const requiredRule = {
      messageName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.message.message_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.message.add_new_message') : this.$t('label.message.edit_message')
  }

  onSubmit() {
    const isValid = this.$refs.messageSelector.validate()
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !isValid) {
          return
        }
        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let message = null
    const isNew = this.isNew
    if (isNew) {
      message = await dispatch(ACTION_MESSAGES.ADD_NEW, {
        ...this.form,
      })
    } else {
      message = await dispatch(ACTION_MESSAGES.UPDATE, {
        ...this.form,
      })
    }
    if (message) {
      if (isNew) {
        this.$emit('update:onCreate', message)
      } else {
        this.$emit('update:onSelect', message)
      }

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()

      if (this.$refs.messageSelector) {
        this.$refs.messageSelector.validate()
      }

      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>
