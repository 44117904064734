<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.goal_select') }} </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-toolbar>
        <q-btn no-caps color="primary" @click="onAdd()" :label="$t('label.goal.compose_new_goal')" class="full-width" />
      </q-toolbar>
      <q-card-section>
        <q-list separator dense>
          <q-item v-for="goal in goals" :key="goal._id" clickable>
            <q-item-section class="text-bold">
              <q-item-label class="full-width ellipsis">
                {{ goal.title || '-' }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn no-caps outline dense color="primary" @click="onSelect(goal)" :label="$t('select')" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
  <GoalFormModal
    v-if="modalGoalVisible"
    :modalVisible="modalGoalVisible"
    :data="selectedGoal"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelect"
    @update:onCreate="onCreate"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IGoal } from '@/utils/types'
import GoalFormModal from '@/components/goal/GoalFormModal.vue'

@Options({
  components: { GoalFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect', 'update:onCreate'],
})
export default class GoalSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalGoalVisible = false
  selectedGoal: IGoal = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get goals(): IGoal[] {
    const goals = this.$store.getters.goals
    return [...goals].reverse()
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(goal: IGoal) {
    return goal
  }

  @Emit('update:onCreate')
  onCreate(goal: IGoal) {
    return goal
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedGoal = {
      app_id: this.appId,
    }

    this.modalGoalVisible = true
  }

  onCloseModal() {
    this.modalGoalVisible = false
    this.selectedGoal = {}
  }
}
</script>
