import { render } from "./ScenarioTemplateSettingForm.vue?vue&type=template&id=177f6dcc&scoped=true"
import script from "./ScenarioTemplateSettingForm.vue?vue&type=script&lang=ts"
export * from "./ScenarioTemplateSettingForm.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-177f6dcc"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QFooter,QToolbar,QSpace,QBtn});
