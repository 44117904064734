<template>
  <q-form ref="formRef" greedy>
    <span class="text-bold text-blue" v-if="stateEdit">{{ $t('editing') }}</span>
    <div class="q-mt-sm">
      <label class="text-grey-9 text-bold">{{ $t('label.scenario_template.template_name') }}</label>
      <div class="q-mt-sm">
        <q-input
          v-model="form.title"
          dense
          outlined
          :placeholder="$t('label.scenario_template.template_name')"
          class="text-h6"
          :rules="[(val) => (val && val.length > 0) || requiredRule.campaignName]"
        />
      </div>
    </div>
    <ScenariosMaker
      v-model="scenarios"
      :campaignId="form._id"
      :directionType="directionType"
      :isTestMode="false"
      :isReport="false"
      :campaign="form"
      @update:editingMode="scenarioEditingMode"
      @update:onChangeDirection="onChangeDirection"
      @update:onChangeScenarios="onChangeScenarios"
      :key="componentKey"
    />

    <q-footer class="bg-grey-2 q-py-sm q-mx-xs">
      <q-toolbar class="q-gutter-sm">
        <q-space />
        <q-btn :disable="isScenarioEditingMode" no-caps class="btn-cancel" @click="onCancel">
          {{ $t('cancel') }}
        </q-btn>
        <q-btn :disable="isScenarioEditingMode || !stateEdit" no-caps class="btn-revert" @click="onRevert">
          {{ $t('revert') }}</q-btn
        >
        <q-btn :disable="isScenarioEditingMode" no-caps class="btn-save" @click="onSubmit"> {{ $t('reflect') }}</q-btn>
      </q-toolbar>
    </q-footer>
  </q-form>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import ScenarioMixin from '@/components/scenario-template/minxins/ScenarioMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import ScenariosMaker from '@/components/scenarios/ScenariosMaker.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { IScenarioTemplate } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { constant } from '@/utils/constants'
import { ObjectUtils } from '@/utils/objects'

@Options({
  components: {
    ScenariosMaker,
  },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class ScenarioSettingForm extends mixins(ScenarioMixin, BaseFormMixin) {
  loading = false
  directionType = '0'
  componentKey = 0
  isScenarioEditingMode = false
  form: IScenarioTemplate = {
    _id: '',
    app_id: '',
    title: '',
    direction_type: 1,
    scenario: [],
  }

  scenarios: ICard[] = []

  get requiredRule() {
    return {
      campaignName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.scenario_template.template_name'),
      }),
    }
  }

  get stateEdit() {
    return Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)
  }

  onCancel() {
    this.goto('scenario_templates', { app_id: this.selectedAppId })
  }

  async onRevert() {
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      this.fetchSelectingScenarioTemplate()
    }
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false

    if (!this.form._id) {
      this.form.app_id = this.selectedAppId
      success = await dispatch(ACTION_SCENARIO_TEMPLATE.ADD, {
        ...this.form,
      })
    } else {
      success = await dispatch(ACTION_SCENARIO_TEMPLATE.UPDATE, {
        ...this.form,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.initForm = cloneDeep(this.parentForm)
      this.goto('scenario_templates', { app_id: this.selectedAppId })
    }
  }

  scenarioEditingMode(editing: boolean) {
    this.isScenarioEditingMode = editing
  }

  onChangeDirection(value) {
    this.directionType = value
    this.form.direction_type = Number.parseInt(value)
  }

  onChangeScenarios(value) {
    this.scenarios = value
    this.form.scenario = value
  }

  @Watch('actionType', { immediate: true })
  async fetchSelectingScenarioTemplate() {
    if (!this.selectedAppId) {
      return
    }

    if (this.selectedScenarioTemplateId !== '') {
      const data: IScenarioTemplate = await this.$store.dispatch(
        ACTION_SCENARIO_TEMPLATE.LOAD_ITEM,
        this.selectedScenarioTemplateId
      )

      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.title = this.form.title + ' Copy'
        }
      }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.scenarios = this.form.scenario ?? []
    this.directionType = this.form.direction_type?.toString() ?? '0'
  }

  async created() {
    this.fetchSelectingScenarioTemplate()
    if (this.selectedScenarioTemplateId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }

    this.isCancel = false
  }
}
</script>

<style scoped>
</style>
