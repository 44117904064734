<template>
  <label class="text-grey-9 text-bold">{{ $t('details') }} </label>
  <div>
    <FormField v-model="value" :isDeleteFirst="isDeleteFirst" ref="formField"></FormField>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import FormField from '@/components/form/FormField.vue'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IFormDetail } from '@/utils/types'

@Options({
  components: { FormField },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class FormDetail extends Vue {
  @Prop({ default: [] })
  modelValue!: IFormDetail[]

  @Prop()
  isDeleteFirst!: boolean

  get value() {
    return this.modelValue
  }

  set value(value: IFormDetail[]) {
    this.$emit('update:modelValue', value)
  }
}
</script>

<style scoped lang="scss">
</style>
