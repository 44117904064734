<template>
  <div class="row q-py-sm horizontal-line">
    <div class="q-ml-md text-grey-9 col-1 flex items-center">
      <span>{{ $t('label.common.answer.answers') }}</span>
    </div>
    <div class="col q-mr-md">
      <q-btn-toggle
        no-caps
        unelevated
        color="btn-grey"
        text-color="white"
        toggle-color="btn-upload"
        toggle-text-color="white"
        style="border: none"
        emit-value
        :options="typeLists"
        v-model="value.type"
        @update:model-value="toogleEventCalled"
        class="btn-type-selection"
      />
    </div>
  </div>
  <div v-if="value.type === 'text'">
    <AnswerTextForm
      v-model="value.text_answers"
      :isDisabledAddAnswer="isDisabledAddAnswer"
      :isDeleteFirst="isDeleteFirst"
      ref="answerTextForm"
    />
  </div>
  <div v-if="value.type === 'image'">
    <div class="row q-py-sm horizontal-line">
      <q-input
        dense
        outlined
        v-model="value.alt_text"
        :placeholder="$t('label.common.answer.alt_text')"
        lazy-rules
        class="col q-mx-md"
      />
    </div>
    <AnswerCarouselImageForm v-model="value.image_answers" ref="answerCarouselImageForm" />
  </div>
  <div v-if="value.type === 'imagemap'">
    <ImagemapSelector v-model="value.imagemap_answer" ref="imagemapSelector" :isMessage="true" />
  </div>
  <div v-if="value.type === 'button'">
    <ButtonSelector v-model="value.button_answer" ref="buttonSelector" :isQuestion="true" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAnswer } from '@/utils/types'
import { constant, FREETEXT_OPTION_TYPE } from '@/utils/constants'
import AnswerTextForm from '@/components/story/common/answer/AnswerTextForm.vue'
import ImagemapSelector from '@/components/story/common/tappable-area/selectors/ImagemapSelector.vue'
import AnswerCarouselImageForm from '@/components/story/common/answer/AnswerCarouselImageForm.vue'
import ButtonSelector from '@/components/story/common/selectors/ButtonSelector.vue'

@Options({
  components: { AnswerCarouselImageForm, AnswerTextForm, ImagemapSelector, ButtonSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class AnswerForm extends Vue {
  @Prop({})
  modelValue!: IAnswer

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  isDisabledAddAnswer!: boolean

  @Prop({ default: false })
  isImageQuestion!: boolean

  get typeLists() {
    return [
      {
        label: this.$t('label.common.answer.text'),
        value: 'text',
      },
      {
        label: this.$t('label.common.messages.image'),
        value: 'image',
      },
      {
        label: this.$t('label.common.messages.imagemap'),
        value: 'imagemap',
      },
      {
        label: this.$t('label.common.messages.button'),
        value: 'button',
      },
    ]
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAnswer) {
    this.$emit('update:modelValue', value)
  }

  toogleEventCalled(v) {
    console.log(this.value)
    if (v === constant.ANSWER_TYPE.IMAGEMAP) {
      this.$nextTick(() => {
        this.$refs.imagemapSelector.validate()
      })
    }
  }

  validate() {
    let isValid = true
    if (this.value.type === constant.ANSWER_TYPE.IMAGEMAP) {
      isValid = this.$refs.imagemapSelector.validate()
    } else if (this.value.type === constant.ANSWER_TYPE.IMAGE) {
      isValid = this.$refs.answerCarouselImageForm.validate()
    } else if (this.value.type === constant.ANSWER_TYPE.BUTTON) {
      isValid = this.$refs.buttonSelector.validate()
    }

    return isValid
  }

  async created() {
    if (!this.value.freetext_answer) {
      this.value.freetext_answer = {
        option_type: FREETEXT_OPTION_TYPE.FREETEX,
        error_message: '',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.imagemap-list.q-list--bordered) {
  border: none;
}
</style>
