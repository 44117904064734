
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IForm } from '@/utils/types'
import FormFormModal from '@/components/form/FormFormModal.vue'

@Options({
  components: { FormFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect', 'update:onCreate'],
})
export default class FormSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalFormVisible = false
  selectedForm: IForm = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get forms(): IForm[] {
    const forms = this.$store.getters.forms
    return [...forms].reverse()
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(form: IForm) {
    return form
  }

  @Emit('update:onCreate')
  onCreate(form: IForm) {
    return form
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedForm = {
      app_id: this.appId,
    }

    this.modalFormVisible = true
  }

  onCloseModal() {
    this.modalFormVisible = false
    this.selectedForm = {}
  }
}
