import { render } from "./CarouselImageSelector.vue?vue&type=template&id=4a85b5e0&scoped=true"
import script from "./CarouselImageSelector.vue?vue&type=script&lang=ts"
export * from "./CarouselImageSelector.vue?vue&type=script&lang=ts"

import "./CarouselImageSelector.vue?vue&type=style&index=0&id=4a85b5e0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4a85b5e0"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QCarousel,QCarouselSlide,QAvatar,QBtn,QInput,QFile});
